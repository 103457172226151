import type { AppProps } from 'next/app';
import Head from 'next/head';
import Image from 'next/image';
import './global-styles.css';
// react-bootstrap-table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './temporary-styles.css';
import { LogoutButton } from '../components/logout-button';
import { SessionProvider as NextAuthProvider, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { NextPage } from 'next';
// eslint-disable-next-line unused-imports/no-unused-imports
import { isDevelopment } from '../utils/environment';
// eslint-disable-next-line unused-imports/no-unused-imports
import { inspect as xstateInspect } from '@xstate/inspect';
import Link from 'next/link';

// inspired by https://nextjs.org/docs/basic-features/layouts#with-typescript
type NextPageWithLayout = NextPage & {
  maxWidth?: string;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const AppProviders: React.FC<AppPropsWithLayout> = (props) => {
  return (
    <NextAuthProvider
      // Here we have to explicitly specify the base-path to nextauth's login/logout sites.
      // See https://github.com/nextauthjs/next-auth/issues/900
      basePath="/api/auth-next"
    >
      <App {...props} />
    </NextAuthProvider>
  );
};

// any paths that should not include bootstrap styles
// because they have conflicting class names with Tailwind
// and they are marked with !important
// so they are a real bitch
const PATHS_WITHOUT_BOOTSTRAP = [
  '/',
  '/grouping/[groupingId]/overview',
  '/admin/[groupingId]/group-list',
];

// uncomment to turn on xstate inspection
// typeof window !== 'undefined' &&
//   isDevelopment &&
//   xstateInspect({
//     iframe: false,
//   });

const App: React.FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const path = router.pathname;
  useEffect(() => {
    if (status === 'authenticated' && session.user?.email) {
      Sentry.setUser({ email: session.user?.email });
    } else {
      Sentry.setUser(null);
    }
  }, [session, status]);

  const maxContentWidth = Component.maxWidth || 'max-w-screen-xl';
  const isAdmin = path.startsWith('/admin');

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="author" content="UniHelper" />
        <meta name="copyright" content="UniHelper" />
        <meta name="description" content="Portal to manage study groups in the UniHelper system" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {/*TODO JEPPE REMOVE BOOTSTRAP WHEN YOU'RE DONE STYLING MY MESS*/}
        {!PATHS_WITHOUT_BOOTSTRAP.includes(path) && (
          <>
            <link
              href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
              rel="stylesheet"
              integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
              crossOrigin="anonymous"
            />

            <link
              rel="stylesheet"
              href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
              integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
              crossOrigin="anonymous"
            />
          </>
        )}
        {favicon}
      </Head>
      <div className="flex flex-col min-h-screen">
        <header className={`shadow-md fixed w-full z-10 ${isAdmin ? 'bg-yellow-500' : 'bg-white'}`}>
          <div className={`${maxContentWidth} px-8 py-2 flex items-center mx-auto `}>
            <Link href="/">
              <a>
                <Image
                  src="/unihelper-horizontal-logo.svg"
                  alt="UniHelper logo"
                  width={170}
                  height={40}
                />
              </a>
            </Link>
            {isAdmin && (
              <Link href="/admin">
                <p className="px-8 text-xl cursor-pointer">Administrator</p>
              </Link>
            )}
            <div className="w-2" />
            <LogoutButton />
          </div>
        </header>
        <div className="bg-slate-100 flex flex-grow mt-14">
          <main className={`${maxContentWidth} mx-auto w-full flex flex-grow px-8`}>
            <Component {...pageProps} />
          </main>
        </div>
        <footer className="bg-blue-900">
          <div
            className={`${maxContentWidth} flex flex-col sm:flex-row gap-2 w-full px-8 py-4 mx-auto text-white`}
          >
            <a
              href="https://www.unihelper.dk/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="underline self-center sm:self-start flex-1"
            >
              Privacy Policy
            </a>
            <div className="flex flex-col items-center flex-1">
              <a href="mailto:portal@unihelper.dk" className="underline">
                portal@unihelper.dk
              </a>
              <a href="tel:+4523676985" className="underline">
                +45 23 67 69 85
              </a>
            </div>
            <div className="flex flex-col items-center sm:items-end flex-1">
              <a
                href="https://www.google.com/maps/place/Ugelrisvej+40,+9632+M%C3%B8ldrup/@56.6080005,9.4318734,17z/data=!3m1!4b1!4m5!3m4!1s0x464958d89a5be52b:0xa1e3c1ced599686d!8m2!3d56.6079884!4d9.434011"
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-right"
              >
                Ugelrisvej 40, 9632 Møldrup, Denmark
              </a>
              <span>CVR: 39750007</span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

const favicon = (
  <>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2186eb" />
    <meta name="msapplication-TileColor" content="#2d89ef" />
    <meta name="theme-color" content="#2d89ef" />
  </>
);

export default AppProviders;
