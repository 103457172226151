const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

export const isomorphicSentryConfig = {
  dsn: SENTRY_DSN || 'https://39249109f3ee4752a25e4e66bf0666bc@o568824.ingest.sentry.io/5714167',
  tracesSampleRate: 0.2,
  // debug: process.env.NODE_ENV === 'development',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
};
