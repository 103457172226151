import { FunctionComponent } from 'react';
import { signOut, useSession } from 'next-auth/react';

export const LogoutButton: FunctionComponent = () => {
  const { status } = useSession();

  // If we're not signed in just render nothing
  if (status !== 'authenticated') {
    return null;
  }

  return (
    <button
      className="ml-auto end flex items-center justify-center whitespace-nowrap rounded-md border border-red-600 text-red-600 hover:bg-red-100 hover:border-red-100 transition-colors py-1 px-3"
      onClick={() => signOut({})}
    >
      👋 Sign out
    </button>
  );
};
